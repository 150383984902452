<template>
  <div class="hm-visitor" :class="{ '-base-white': baseWhite }">
    <IconVisitorFoot v-if="iconFoot" class="icon" />
    <IconVisitor v-else class="icon" />
    <span class="number">{{ displayCount }}</span>
  </div>
</template>

<script setup lang="ts">
import IconVisitor from '@/assets/icons/icon_visitor.svg'
import IconVisitorFoot from '@/assets/icons/icon_visitor_foot.svg'

type Props = {
  count: number
  threshold?: number
  baseWhite?: boolean
  iconFoot?: boolean
}

const props = defineProps<Props>()

const displayCount = computed(() =>
  props.threshold
    ? convertNumberToPlus(props.count, props.threshold)
    : props.count
)
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.hm-visitor {
  align-items: center;
  color: v.$white;
  display: flex;
  gap: v.space(0.5);
  height: 26px;
  padding: 1px v.space(1);

  @include m.sp() {
    height: 18px;
  }

  .icon {
    fill: v.$white;
    height: 16px;
    width: 16px;
    @include m.sp() {
      height: 12px;
      width: 12px;
    }
  }

  > .number {
    @include m.sp() {
      font-size: 12px;
    }
  }

  &.-base-white {
    color: v.$text-note;
    height: 32px;

    > .icon {
      fill: v.$text-note;
      height: 12px;
      width: 12px;
    }
  }
}
</style>
